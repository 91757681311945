
<template>
  <main class="main_v8">
    <VideoPlayer
        type="default"
        @pause="processPause"
        previewImageLink="poster.webp"
        link="https://customer-rffr4tc2yy6o759d.cloudflarestream.com/430590c7846d6acfae64e7100bc1a37e/manifest/video.m3u8"
        :progress="0.1"
        :isMuted="false"
        :isControls="true"
        class="videoclass"
    />
  </main>
</template>

<script setup>
  import { VideoPlayer } from 'vue-hls-video-player';

  function processPause(progress) {
    console.log(progress)
  }
</script>


<style lang="scss" scoped>
  .main_v8{
    padding-top: 20px;
  } 
  .videoclass {
    width:70%;
  }
</style>


